import React, { useEffect, useState, useRef, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Conteiner } from './style';
import { useEvent } from '../../hooks/EventContext';
import { useTranslation } from 'react-i18next';
import pt from '../../assets/images/pt.png';
import en from '../../assets/images/en.png';
import es from '../../assets/images/es.png';
import { asEnumerable } from 'linq-es2015';
const langImgs: any = {
  pt,
  en,
  es
}

const Languages: React.FC<any> = (props: any) => {

  const eventContext = useEvent();
  const { t, i18n } = useTranslation();

  function setLanguage(language: string) {

    i18n.changeLanguage(language);
    localStorage.setItem('defaultLanguage', language);
  }


  return (
    <>
      {eventContext.event?.langs?.length > 1 && <Conteiner>
        {
          asEnumerable(eventContext.event?.langs as string[] || []).OrderBy((lang: string) => lang).Select((l: any, index1?: number) => (

            <a key={index1} style={{ cursor: 'pointer', display: 'inline', padding: 15 }} onClick={() => setLanguage(l)} className="navi-link">
              <span className="symbol symbol-20 mr-3">
                <img
                  src={langImgs[l]}
                  alt={l}
                  width="40"
                />
              </span>
            </a>


          )).ToArray()
        }
      </Conteiner>}
    </>
  );
};

export default Languages;
