import 'firebase/storage';
import 'react-image-crop/dist/ReactCrop.css';

import { AnimationContainer, Container } from './styles';
import { Button, Modal } from 'antd';
import React, { useContext, useEffect, useState } from 'react';

import $ from 'jquery';
import Image from '../../components/Image';
import { Link } from 'react-router-dom';
import ReactCrop from 'react-image-crop';
import { asEnumerable } from 'linq-es2015';
import firebase from 'firebase';
import navBrandImage from '../../assets/images/video/9MMutZm.png';
import profilePicture from '../../assets/images/profile-picture.png';
import { useAuth } from '../../hooks/FirebaseAuth';
import { useEvent } from '../../hooks/EventContext';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

const DashboardMasterPage: React.FC = (props: any) => {
  const { t, i18n } = useTranslation();
  const { signOut, getUser } = useAuth();
  const user = getUser();
  const eventContext = useEvent();
  const [preImgLoad, setPreImgLoad] = useState<any>();
  const [imageRef, setImageRef] = useState<any>();
  const [croppedImageUrl, setCroppedImageUrl] = useState("");
  const [buffer, setBuffer] = useState<ArrayBuffer>();
  const [crop, setCrop] = useState<any>({
    unit: '%',
    width: 30,
    aspect: 5 / 5,
  });

  const changePicture = async () => {
    let imgUrl: string = "";
    if (!!buffer) {
      const uploaded: any = await firebase.storage().ref(`/companies/${props.params.event?.toLowerCase()}/profile/${user.id}.png`).put(buffer, {
        contentType: 'image/png' // That's the event object from the Cloud Functions trigger
      });
      imgUrl = await uploaded.ref.getDownloadURL() || "";
    }

    handleCancel();
  }

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setPreImgLoad(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  const onImageLoaded = (image: any) => {
    setImageRef(image);
  };

  const onCropComplete = (crop: any) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop: any, percentCrop: any) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    setCrop(crop);
  };

  async function makeClientCrop(crop: any) {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef,
        crop,
        'newFile.jpeg'
      );
      setCroppedImageUrl(croppedImageUrl);
    }
  }

  const getCroppedImg = (image: any, crop: any, fileName: any): any => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx?.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise<any>((resolve, reject) => {
      canvas?.toBlob(async (blob: any) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        setBuffer(await blob.arrayBuffer());
        resolve(window.URL.createObjectURL(blob));
      }, 'image/jpeg');
    });
  }

  const handleCancel = () => {
    setPreImgLoad(null);
  };

  useEffect(() => {
    eventContext.loadInfo(props.params.event?.toLowerCase(), props.params.channel);
  }, [props.params.event, props.params.channel]);

  function doUppercase(str: string, bUpperCase: boolean) {
    if (bUpperCase) {
      return str.toLocaleUpperCase();
    }
    return str;
  }

  function setLanguage(language: string) {

    i18n.changeLanguage(language);
    localStorage.setItem('defaultLanguage', language);

    // TODO: reload the channel instead of reloading the page
    // window.location.reload();

  }

  function updatePage(language: any){
    console.log( language.lang);
    i18n.changeLanguage(language.lang);
    localStorage.setItem('defaultLanguage', language.lang);
    console.log('updatePage');
    window.location.reload();
  }

  const navBarAllCaps = eventContext.event?.customization?.navbar?.allCaps;
  const footerBackgroundColor = eventContext.event?.customization?.footer?.backgroundColor;
  const footerFontColor = eventContext.event?.customization?.footer?.fontColor;

  // TODO: remove try
  try {
    const bgColor = eventContext.event?.customization?.backgroundColor;
    if (bgColor) {
      let body = document.getElementsByTagName('body')[0];
      let html = document.getElementsByTagName('html')[0];

      body.style.backgroundColor = bgColor;
      html.style.backgroundColor = bgColor;
    }
  } catch { }


  return eventContext.event && (
    <AnimationContainer customization={eventContext.event?.customization || {}}>
      <Modal
        visible={!!preImgLoad}
        title="Recortar"
        onOk={changePicture}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={changePicture}>
            Enviar
          </Button>,
        ]}
      >
        {preImgLoad && (
          <ReactCrop
            src={preImgLoad}
            crop={crop}
            circularCrop={true}
            ruleOfThirds
            onImageLoaded={onImageLoaded}
            onComplete={onCropComplete}
            onChange={onCropChange}
          />
        )}
      </Modal>

      <Container customization={eventContext.event?.customization || {}} className="root-container">

        {/* BRF PIXEL */}
        {(eventContext?.event?.key?.indexOf('brf') != -1) &&
          (<br />)
        }
        <div className="container-for-dashboard-background" dangerouslySetInnerHTML={{ __html: eventContext.event?.customization?.customDashboardBackground }}></div>{/* //! Denys adicionou */}
        <nav className="navbar navbar-fixed-top" style={{
          fontFamily: eventContext.event?.customization?.navbar?.customFontName,
          opacity: eventContext.event?.customization?.navbar?.opacity
        }}>
          <div className="container">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#myNavbar"
              >
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              {eventContext.event?.customization?.navbar?.logo &&
                <a className="navbar-brand" href={`/${props.params.event?.toLowerCase()}`}>
                  <img src={eventContext.event.customization.navbar.logo} />
                </a>
                ||
                !eventContext.event?.settings?.internalLogo &&
                <a className="navbar-brand" href={`/${props.params.event?.toLowerCase()}`}>
                  <img src={eventContext.event?.customization?.logo || navBrandImage} />
                </a>
              }
            </div>
            <div className="collapse navbar-collapse" id="myNavbar">
              <p className="navbar-text">{doUppercase(eventContext.event?.subtitle_languages ? eventContext.event?.subtitle_languages[i18n.language] : '', navBarAllCaps)}</p>
              <ul className="nav navbar-nav navbar-right">
                {(eventContext.channel?.links || eventContext.event?.links || []).length ? <>
                  {
                    (eventContext.channel?.links || eventContext.event?.links || []).map((link: any, i: number) => {
                      return <React.Fragment key={i}>
                        {
                          !link?.links?.length && link.url != 'channels' &&
                          <li key={i} className="active"><a href={`${link.url.replace('{eventKey}', props.params.event?.toLowerCase())}`}>{doUppercase(link.name_languages ? link.name_languages[i18n.language] : link.name, navBarAllCaps)}</a></li>
                        }

                        {
                          !!link?.links?.length && link.url != 'channels' &&
                          <li className="dropdown">
                            <a className="dropdown-toggle" data-toggle="dropdown" href="#">{doUppercase(link.name_languages ? link.name_languages[i18n.language] : link.name, navBarAllCaps)}<span className="caret"></span></a>
                            <ul className="dropdown-menu">
                              {link?.links?.map((item: any, i: number) => (
                                <li key={i}><a href={`${item.url.replace('{eventKey}', props.params.event?.toLowerCase())}`}>{doUppercase(item.name_languages ? item.name_languages[i18n.language] : item.name, navBarAllCaps)}</a></li>
                              ))}
                            </ul>
                          </li>
                        }

                        {
                          link.url == 'channels' &&
                          <li className="dropdown">
                            <a className="dropdown-toggle" data-toggle="dropdown" href="#">{doUppercase(link.name_languages ? link.name_languages[i18n.language] : link.name, navBarAllCaps)}<span className="caret"></span></a>
                            <ul className="dropdown-menu">
                              {(asEnumerable(eventContext.channels || []).Where((a: any) => !a.url)).Select((item: any, i: number | undefined) => {
                                return <li key={i} onClick={() => { updatePage(item); }}><Link to={`/${props.params.event?.toLowerCase()}/dashboard/${item.key}`}>{doUppercase(!!item?.name_languages ? item?.name_languages[i18n.language] || '' : item?.name || '', navBarAllCaps)}</Link></li>
                              }).ToArray()}
                            </ul>
                          </li>
                        }

                      </React.Fragment>
                    })
                  }
                </>
                  :

                  <>
                    <li className="active"><a href={`/${props.params.event?.toLowerCase()}/`}>Home</a></li>
                    <li className="active"><a href={`/${props.params.event?.toLowerCase()}/schedule.html`}>Programação</a></li>
                    <li className="dropdown">
                      <a className="dropdown-toggle" data-toggle="dropdown" href="#">Salas<span className="caret"></span></a>
                      <ul className="dropdown-menu">
                        {(asEnumerable(eventContext.channels || []).Where((a: any) => !a.url)).Select((item: any, i: number | undefined) => {
                          return <li key={i} ><Link  to={`/${props.params.event?.toLowerCase()}/dashboard/${item.key}`}>{doUppercase(!!item?.name_languages ? item?.name_languages[i18n.language] || '' : item?.name || '', navBarAllCaps)}</Link></li>
                        }).ToArray()}
                      </ul>
                    </li>
                    <li><Link to={`/${props.params.event?.toLowerCase()}/files`}>Arquivos</Link></li>

                    {/* <li style={{ cursor: 'pointer' }}><a onClick={() => { signOut() }}>Sair</a></li> */}

                  </>
                }

                {user?.fields?.voucher && <li className="voucher"><a href="/hypera-lidera-3-trimestre/ifood.html">iFood</a></li>}

                {/* <li><a style={{ cursor: 'pointer' }} onClick={() => { (window as any).jivo_api.open() }}>Ajuda</a></li> */}
                {eventContext.event?.settings?.uploadDisabled && <li style={{ cursor: 'pointer' }}>
                  <Link to={`/${props.params.event?.toLowerCase()}/logout`}>{t('Logout')}</Link>
                </li>}

                {!eventContext.event?.settings?.uploadDisabled && <li className="dropdown">
                  <a className="dropdown-toggle profile-menu" data-toggle="dropdown" href="#">
                    <Image className="profile-picture" src={croppedImageUrl || `https://firebasestorage.googleapis.com/v0/b/antonicelli-official.appspot.com/o/companies%2F${props.params.event?.toLowerCase()}%2Fprofile%2Fthumbs%2F${user.id}_35x35.png?alt=media&guid=${v4()}`} default={profilePicture} />
                    {doUppercase(user?.name?.split(' ')[0] || user?.email?.split('@')[0], navBarAllCaps)}<span className="caret"></span>
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <input onChange={onSelectFile} className="profile-file" type="file" />
                      <a style={{ cursor: 'pointer' }} onClick={() => { $('.profile-file').click() }}>{t('Change my picture')}</a>
                    </li>
                    <li>
                      <Link to={`/${props.params.event?.toLowerCase()}/logout`}>{t('Logout')}</Link>
                    </li>
                  </ul>
                </li>}
                {eventContext.event?.settings?.languageSelector && eventContext.event?.langs &&
                  <li className="dropdown">
                    <a className="dropdown-toggle" data-toggle="dropdown" href="#">{t('Language')}<span className="caret"></span></a>
                    <ul className="dropdown-menu">
                      {eventContext.event.langs.map((item: any, i: number) => (
                        <li key={`${i}dropdown`} ><a onClick={() => { setLanguage(item); }}>
                          {item === 'pt' ? 'Português' : 'English'}</a></li>
                      ))}
                    </ul>
                  </li>
                }
              </ul>
            </div>
          </div>
        </nav>

        {props.children}


        {
          !eventContext.event?.customization?.footerCss && <>
            {
              !eventContext.event?.customization?.footerBackground &&
              <footer className="internal-footer" style={{
                backgroundColor: footerBackgroundColor ? footerBackgroundColor : 'white',
                padding: '0px'
              }}>

                {(!eventContext.event?.customization?.footer?.type ||
                  eventContext.event?.customization?.footer?.type === 'horizontal') &&
                  <div className="container" style={{ padding: "15px" }}>
                    <div className="copyright col-md-8" style={{ color: footerFontColor ? footerFontColor : 'black' }}>
                      <b>{(eventContext.event?.customization?.footer?.text && eventContext.event.customization.footer.text[i18n.language]) || eventContext.event?.customization?.footer?.text || t("In case of technical problems send an email to")}</b><br />
                      {(eventContext.event?.customization?.footer?.subtext && eventContext.event.customization.footer.subtext[i18n.language]) || eventContext.event?.customization?.footer?.subtext || `Copyright © 2023 | ${eventContext.event?.name?.toUpperCase()} ${t("All rights reserved")}.`}
                    </div>
                    <div className="footer-logo col-md-4" style={{ textAlign: 'right' }}>
                      <img className="footer-logo-img"  style={{ height: 50 }} src={eventContext.event?.customization?.footerLogo} alt="Logo" />
                    </div>
                  </div>
                }

                {eventContext.event?.customization?.footer?.type === 'vertical' &&
                  <div className="container" style={{ padding: "25px", textAlign: "center" }}>
                    <img style={{ width: "16%", marginBottom: "0.5%" }} src={eventContext.event?.customization?.footerLogo} alt="Logo" />
                    <br />
                    <b>{(eventContext.event?.customization?.footer?.text && eventContext.event.customization.footer.text[i18n.language]) || eventContext.event?.customization?.footer?.text || t("In case of technical problems send an email to")}</b><br />
                    {(eventContext.event?.customization?.footer?.subtext && eventContext.event.customization.footer.subtext[i18n.language]) || eventContext.event?.customization?.footer?.subtext || `Copyright © 2023 | ${eventContext.event?.name?.toUpperCase()} ${t("All rights reserved")}.`}
                  </div>
                }

              </footer>
            }

            {
              !!eventContext.event?.customization?.footerBackground &&
              <footer style={{ backgroundImage: `url('${eventContext.event?.customization?.footerBackground}')`, height: 150, backgroundRepeat: 'no-repeat', backgroundPosition: 'center bottom' }}>
              </footer>
            }
          </>



        }

      </Container>





      {!!eventContext.event?.customization?.footerCss && <footer></footer>}
    </AnimationContainer >
  );
};

export default DashboardMasterPage;
